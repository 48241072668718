export type LogLevelString = 'off'|'fatal'|'error'|'warning'|'information'|'debug'|'verbose'|string;

/**
 * Language definition
 */
export interface LanguageDef
{
    /**
     * Language shortcut used
     */
    lang: string;

    /**
     * Language display name
     */
    name: string;
}

export interface SettingsConfiguration
{
    /**
     * Indication that application is running in debug mode
     */
    debug: boolean;

    /**
     * Base url that is used for accessing REST api
     */
    apiBaseUrl: string;

    /**
     * Object hodling default headers that are send with rest requests
     */
    defaultApiHeaders: { [key: string]: string };

    /**
     * Array of available themes
     */
    themes: string[];

    /**
     * Available languages for application
     */
    languages: LanguageDef[];
}

export interface SettingsGeneral
{
    /**
     * Default visual theme of application
     */
    theme: string;

    /**
     * Default selected language
     */
    language: string;
}

export interface SettingsDebug
{
    /**
     * Indication whether is console (logger sink) enabled
     */
    consoleEnabled: boolean;

    /**
     * Indication whether are debug data enabled
     */
    debugData: boolean;
}

export interface SettingsLogging
{
    /**
     * Minimal log level for console sink
     */
    consoleLogLevel: LogLevelString;
}

/**
 * Configuration object
 */
export interface Configuration
{
    /**
     * Static configuration for application
     */
    configuration?: SettingsConfiguration;

    /**
     * General settings
     */
    general?: SettingsGeneral;

    /**
     * Debug settings, used for debugging purposes
     */
    debug?: SettingsDebug;

    /**
     * Logging setting, allows to configure logger sinks
     */
    logging?: SettingsLogging;
}

export const config: Configuration = 
{
    configuration:
    {
        debug: !isProduction,
        apiBaseUrl: "api/",
        defaultApiHeaders: 
        {
            Accept: "application/json"
        },
        themes:
        [
            "light",
            "dark"
        ],
        languages:
        [
            {
                lang: "en",
                name: "English"
            },
            {
                lang: "sk",
                name: "Slovenčina"
            }
        ]
    },
    general:
    {
        theme: "dark",
        language: "en"
    },
    debug:
    {
        consoleEnabled: !isProduction,
        debugData: false
    },
    logging:
    {
        consoleLogLevel: "verbose"
    }
};

